import React from 'react';
import { withRouter } from 'react-router-dom'


class Summary extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      isShowingMessage: false,
    };
    
  }
  
  componentDidUpdate(prevProps, prevState) {
    var self = this;
    
    if (!prevProps.isActive && this.props.isActive) {
      setTimeout(function() {
        self.setState({isShowingMessage: true});
      }, 2500);
    }
    
  }
  
  componentDidMount() {
//     var self = this;
  }
  
  
  render() {
    
    const { isActive } = this.props;
    
    var self = this,
        wrapperClasses = ['photo-journey__content__section'];
    
    if(isActive) {
      wrapperClasses.push('photo-journey__content__section--active');
    }
    
    return (
      
      <div className={wrapperClasses.join(' ')}>
        
        <div className="photo-journey__content__section__full-width">
          
          <div className="photo-summary">
            <div className="photo-summary__diagram">
              
              <div className="photo-summary__diagram__body">
                
                <div className="photo-summary__diagram__device photo-summary__diagram__device--phone-suzi"></div>
                
                {this.props.shareChain.map((person, index) => {
                  var classes = ['photo-summary__diagram__device', 'photo-summary__diagram__device--'+person.device, 'photo-summary__diagram__device--index-'+index];
                  return (
                    <div className={classes.join(' ')}>
                      <div className={'person-profile person-profile--person'+person.id}></div>
                    </div>
                  )
                  
                })}

                <div className="photo-summary__diagram__photo-circle"></div>
              </div>

            </div>
            <h1>See how a photo can be shared to so many people. You should always ask somebody’s permission before sharing photos.</h1>
            
            <div className="photo-summary__buttons">
              <button onClick={self.props.onSelectFinish} className="button button--wide button--wide--tick"><span>Finish</span></button>
            </div>
            
          </div>

        </div>
                
      </div>
      
    )
  }
};


export default withRouter(Summary);
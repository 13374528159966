import React from 'react';
import { motion } from "framer-motion";
import { withRouter } from 'react-router-dom';
import { WhoCanHelpGameData } from './../../data/GameData';
import { headingTransition, headingDetailTransition, fadeInTransition, headingIconTransition, menuTransition, menuItemImageTransition, menuItemLabelTransition } from './../../data/Transitions';

import { ReactSVG } from 'react-svg';
import questionMark from '../../images/icon-question-mark.svg';

class ScenarioPicker extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      heading: WhoCanHelpGameData.heading,
      scenarios: WhoCanHelpGameData.scenarios,
      showBall: true,
      isMounted: false,
    };
  }
  
  componentDidMount() {
    
    this.setState({isMounted: true})
    
  }
  
  render() {
    const { isMounted, scenarios } = this.state;

    var self = this;
    
    return (
        
      <motion.div className="container" initial="unmounted" animate={(isMounted) ? 'mounted' : 'unmounted'} variants={fadeInTransition}>
          
        <div className="who-can-help">

          <div className="who-can-help__header">
            <div className="who-can-help__header__inner">
              
              <motion.div initial="unmounted" animate={isMounted ? 'mounted' : 'unmounted'} variants={headingIconTransition}>
                <div className="who-can-help__header__question-mark">
                  <ReactSVG src={questionMark} />
                </div>
              </motion.div>
              
              <motion.h1 initial="unmounted" animate={isMounted ? 'mounted' : 'unmounted'} variants={headingTransition} className="who-can-help__header__title">
                { WhoCanHelpGameData.heading }
              </motion.h1>
              
            </div>
            <motion.div initial="unmounted" animate={isMounted ? 'mounted' : 'unmounted'} variants={headingDetailTransition} className="who-can-help__header__detail">
              <div className="pyramid"></div>
            </motion.div>
          </div>
          
          <div className="who-can-help__main">
            
            <motion.ul 
              className="picker-menu"
              variants={menuTransition}
              initial="unmounted"
              animate={isMounted ? "mounted" : "unmounted"}
            >
              
                {scenarios.map(scenario => {

                  return (
                    <li key={scenario.id}>
                      <div className={"picker-button " + (scenario.completed ? 'picker-button--completed' : '')}>
                        <div className="picker-button__image">
                          <motion.div variants={menuItemImageTransition}>
                            <ReactSVG src={require('../../images/'+scenario.icon)} />
                          </motion.div>
                        </div>
                        <motion.h2 variants={menuItemLabelTransition} initial="unmounted" className="picker-button__label"><span>{ scenario.name }</span></motion.h2>
                          
                        {/* 
                          For accessibility, adding a separate button that covers this element
                          to ensure it is compatible with mouse, touch, keyboard and screen readers
                         */}
                        <button onClick={(e) => self.props.onSelectScenario(scenario)} className="picker-button__button"><span className="sr-only">Select</span></button>
                      </div>
                    </li>
                  )
                  
                })}

            </motion.ul>
            
          </div>
          
        </div>
          
      </motion.div>
      
    )
  }
};


export default withRouter(ScenarioPicker);

import React from 'react';
import { motion } from "framer-motion";
import { withRouter } from 'react-router-dom';
import { WhoCanHelpGameData } from './../../data/GameData';
import { headingTransition, menuTransition, menuItemImageTransition, menuItemLabelTransition, selectedOptionIconTransition, fadeInTransition } from './../../data/Transitions';

import { ReactSVG } from 'react-svg';
import blob from '../../images/blob.svg';
import correctBadge from '../../images/icon-correct.svg';

class ScenarioPicker extends React.Component {
  
  constructor(props) {
    super(props)
    
    const slug = this.props.match ? this.props.match.params.slug : null,
          scenario = WhoCanHelpGameData.scenarios.find((scenario) => { return scenario.slug === slug });
    
    this.state = {
      showBall: true,
      isMounted: false,
      isChecking: false,
      checkingOption: null,
      selectedOption: null,
      selectedOptions: [],
      scenario: scenario,
    };
    
    //this.didSelectScenario = this.didSelectScenario.bind(this);
  }
  
  componentDidMount() {
    this.setState({isMounted: true})
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    
    const slug = this.props.match ? this.props.match.params.slug : null;
    
    if(this.state.scenario.slug !== slug) {
      var newScenario = WhoCanHelpGameData.scenarios.find((scenario) => { return scenario.slug === slug });
      this.setState({scenario: newScenario, selectedOptions: []})
    }
    
  }
  
  shouldSelectOption = (event, option) => {
    
    var self = this,
        selectOptionsMutable = this.state.selectedOptions;
    
    if(!selectOptionsMutable.includes(option) ) {
      self.setState({isChecking: true, checkingOption: option});
      
      setTimeout(function() {
        selectOptionsMutable.unshift(option);
        self.setState({selectedOptions: selectOptionsMutable, isChecking: false});

        if(option.correct) {
          self.state.scenario.completed = true;
        }
        
      }, 500);

    }
    
  }
  
  titleAnimationState = () => {
    if(this.state.isChecking) {
      return 'unmounted';
    }
    return this.state.isMounted ? 'mounted' : 'unmounted';
  }
  
  render() {
    const { isMounted, selectedOptions, scenario } = this.state;
    
    var self = this;
    
    return (
      
        
      <div className="container">
          
        <div className="who-can-help">

          <div className="who-can-help__header">
            <div className="who-can-help__header__inner">
              
              <motion.div className="who-can-help__header__selected-icon" initial="unmounted" animate={isMounted ? 'mounted' : 'unmounted'} variants={fadeInTransition}>
                <div className="who-can-help__header__selected-icon__wrapper">
                  <motion.div initial="unmounted" animate={isMounted ? 'mounted' : 'unmounted'} variants={selectedOptionIconTransition}>
                    <ReactSVG src={require('../../images/'+scenario.icon)} />
                  </motion.div>
                </div>
              </motion.div>
              
              {
                  selectedOptions.length ?
                  
                    <motion.h1 initial="unmounted" animate={self.titleAnimationState()} variants={headingTransition} className="who-can-help__header__title">
                      { selectedOptions[0].prompt }
                    </motion.h1>
              
                      :
              
                    <motion.h1 initial="unmounted" animate={self.titleAnimationState()} variants={headingTransition} className="who-can-help__header__title">
                      { scenario.question }<br />Who should I tell?
                    </motion.h1>
                    
              }
              
            </div>
            <div className="blob">
              <ReactSVG src={blob} />
            </div>
          </div>
          
          <div className="who-can-help__main">
            
            <motion.ul 
              className="picker-menu picker-menu--person-picker"
              variants={menuTransition}
              initial="unmounted"
              animate={isMounted ? "mounted" : "unmounted"}
            >
              
              {scenario.options.map(option => {

                var isSelected = selectedOptions.includes(option),
                    willSelect = option === self.state.checkingOption,
                    classes = ['picker-button'];
                
                if(isSelected || willSelect) {
                  classes.push('picker-button--selected');
                }
                
                if(option.correct) {
                  classes.push('picker-button--is-correct');
                } else {
                  classes.push('picker-button--is-incorrect');
                }

                return (
                  <li key={option.id}>
                    <div className={classes.join(' ')}>
                      
                      <div className="picker-button__image picker-button__image--normal">
                        <motion.div variants={menuItemImageTransition}>
                          <ReactSVG src={require('../../images/'+option.icon)} />
                        </motion.div>
                      </div>
                      
                      <div className="picker-button__image picker-button__image--selected">
                        <ReactSVG src={require('../../images/'+option.iconSelected)} />
                      </div>
                      
                      {isSelected && option.correct &&
                        <div className="picker-button__tick">
                          <ReactSVG src={correctBadge} />
                        </div>
                      }
                      
                      <motion.h2 variants={menuItemLabelTransition} initial="unmounted" className="picker-button__label"><span>{ option.name }</span></motion.h2>
                        
                      {/* 
                        For accessibility, adding a separate button that covers this element
                        to ensure it is compatible with mouse, touch, keyboard and screen readers
                       */}
                      <button onClick={(e) => self.shouldSelectOption(e, option)} className="picker-button__button"><span className="sr-only">Select</span></button>
                    </div>
                  </li>
                )
                
              })}
              
            </motion.ul>
            
          </div>
          
        </div>
          
      </div>
      
    )
  }
};


export default withRouter(ScenarioPicker);

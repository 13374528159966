import React from 'react';
import { motion } from "framer-motion";
import { withRouter } from 'react-router-dom';
import { WhoCanHelpGameData } from './../../data/GameData';
import { fadeUpTransition, scaleUpTransition, fadeRightTransition, menuTransition, menuItemImageTransition, menuItemLabelTransition } from './../../data/Transitions';

import { ReactSVG } from 'react-svg';

class Summary extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      scenarios: WhoCanHelpGameData.scenarios,
      isMounted: false,
    };
  }
  
  componentDidMount() {
    this.setState({isMounted: true})
  }
  
  render() {
        
    const { isMounted, scenarios } = this.state;
    
    return (
      
        
      <div className="container">
          
        <div className="summary">

          <motion.div className="summary__header" variants={fadeUpTransition} initial="unmounted" animate={isMounted ? "mounted" : "unmounted"}>
            <h1 className="summary__header__title">Who Can Help?</h1>
            <p>We all should seek help when we feel like we need it.</p>
          </motion.div>
          
          <div className="summary__main">
            
            <motion.ul 
              className="summary-list"
              variants={menuTransition}
              initial="unmounted"
              animate={isMounted ? "mounted" : "unmounted"}
            >
              
              {scenarios.map(scenario => {
                
                const correctOption = scenario.options.find((item) => {
                    return item.correct === true;
                });
                
                if(correctOption == null) {
                  return ('');
                }
                
                return (
                  <li key={scenario.id}>
                    
                    <article className="summary-list__item">
                      
                      <div className="picker-button summary-list__item__scenario">
                        <div className="picker-button__image">
                          <motion.div variants={menuItemImageTransition}>
                            <ReactSVG src={require('../../images/'+scenario.icon)} />
                          </motion.div>
                        </div>
                        <motion.h2 variants={menuItemLabelTransition} initial="unmounted" className="picker-button__label"><span>{ scenario.name }</span></motion.h2>
                      </div>
                      
                      <motion.div className="summary-list__item__arrow" variants={fadeRightTransition} initial="unmounted"></motion.div>
                      
                      <motion.div className="summary-list__item__answer" variants={scaleUpTransition} initial="unmounted">
                        
                        <div className="summary-list__item__answer__image">
                          <ReactSVG src={require('../../images/'+correctOption.icon)} />
                        </div>
                        
                        <div className="summary-list__item__answer__body">
                          <h3 className="summary-list__item__answer__title">{ correctOption.name }</h3>
                          <p>{ correctOption.prompt }</p>
                        </div>
                        
                      </motion.div>
                      
                    </article>
                    
                  </li>
              
                )
                
              })}
              
            </motion.ul>
            
          </div>
          
        </div>
          
      </div>
      
    )
  }
};


export default withRouter(Summary);

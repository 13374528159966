export const photoTransition = {
  start: {
    rotate: -50,
    transition: { duration: 2.25, ease: 'easeInOut' }
  },
  end: {
    rotate: 50,
    transition: { duration: 2.25, ease: 'easeInOut' }
  },
}

export const profilePickerTransition = {
  mounted: {
    opacity: 1,
    transition: { staggerChildren: 0.3, delayChildren: 0, delay: 1, when: "beforeChildren" }
  },
  unmounted: {
    opacity: 0,
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
}

export const profilePickerItemTransition = {
  mounted: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring', 
      stiffness: 300, 
      damping: 15,
    }
  },
  unmounted: {
    scale: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}


export const headingTransition = {
  mounted: { opacity: 1, scale: 1, transition: { delay: 1, duration: 0.8, ease: 'backOut' } },
  unmounted: { opacity: 0, scale: 0 }
}

export const headingDetailTransition = {
  mounted: { opacity: 1, scale: 1, transition: { delay: .5, duration: 0.5, ease: 'easeOut' } },
  unmounted: { opacity: 0, scale: 0.6, transition: { duration: 0.5, ease: 'easeIn' } }
}

export const headingIconTransition = {
  mounted: { opacity: 1, rotate: 0, transition: { delay: 1.5, duration: 2, ease: 'easeInOut' } },
  unmounted: { opacity: 0, rotate: -25 }
}

export const menuTransition = {
  mounted: {
    opacity: 1,
    transition: { staggerChildren: 0.3, delayChildren: 0, delay: 1, when: "beforeChildren" }
  },
  unmounted: {
    opacity: 0,
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
}

export const planBodyTransition = {
  mounted: {
    opacity: 1,
    transition: { staggerChildren: 0.1, delayChildren: 0, delay: 1, when: "beforeChildren" }
  },
  unmounted: {
    opacity: 0,
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
}

export const fadeInTransition = {
  mounted: {
    opacity: 1,
    transition: { duration: 0.2 }
  },
  unmounted: {
    opacity: 0,
    transition: {  }
  }
}

export const fadeRightTransition = {
  mounted: {
    opacity: 1,
    x: 0,
    transition: {
      ease: 'backOut', 
      duration: 1,
    }
  },
  unmounted: {
    opacity: 0,
    x: -50,
    transition: {  }
  }
}

export const fadeUpFastTransition = {
  mounted: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'backOut', 
      duration: 0.35,
    }
  },
  unmounted: {
    opacity: 0,
    y: 50,
    transition: {  }
  }
}

export const fadeUpTransition = {
  mounted: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'backOut', 
      duration: 1,
    }
  },
  unmounted: {
    opacity: 0,
    y: 50,
    transition: {  }
  }
}

export const scaleUpTransition = {
  mounted: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring', 
      stiffness: 200, 
      damping: 20,
      //delay: 0.1,
    },
  },
  unmounted: {
    opacity: 0,
    scale: 0,
    transition: {  }
  }
}

export const selectedOptionIconTransition = {
  mounted: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'backOut', 
      duration: 1,
    }
  },
  unmounted: {
    y: 100,
    opacity: 0,
    transition: {
      ease: 'backIn', 
      duration: 0.3,
    }
  }
}

export const menuItemImageTransition = {
  mounted: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'backOut', 
      duration: 0.5,
    }
  },
  unmounted: {
    y: 50,
    opacity: 0,
    transition: {
      ease: 'backIn', 
      duration: 0.3,
    }
  }
}

export const menuItemLabelTransition = {
  mounted: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring', 
      stiffness: 300, 
      damping: 15,
      //delay: 0.1,
    }
  },
  unmounted: {
    scale: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}

export const planItemTransition = {
  mounted: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring', 
      stiffness: 300, 
      damping: 15,
      //delay: 0.1,
    }
  },
  unmounted: {
    y: 30,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}

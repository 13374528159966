import React from 'react';
import { motion } from "framer-motion";
import { withRouter } from 'react-router-dom'
import { ShareThatPhotoGameData } from './../../data/GameData';
import Touchpoint from './Touchpoint';
import TouchpointFinish from './TouchpointFinish';
import Summary from './Summary';

// export const contentTransitionOptions  = { type: 'spring', stiffness: 200, damping: 20, };
export const contentTransitionOptions  = { ease: 'backInOut', duration: 3, };

export const contentTransition  = {
  start: { x: '0%', transition: contentTransitionOptions },
  section1: { x: '-16.666666667%', transition: contentTransitionOptions },
  section2: { x: '-33.333333334%', transition: contentTransitionOptions },
  section3: { x: '-50%', transition: contentTransitionOptions },
  section4: { x: '-66.666666667%', transition: contentTransitionOptions },
  section5: { x: '-83.333333335%', transition: contentTransitionOptions },
}

class PhotoJourney extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      sectionCount: 6,
      currentSection: 0,
      people: ShareThatPhotoGameData.people,
      shareChain: ShareThatPhotoGameData.shareChain,
    };
    
    this.goToNextSection = this.goToNextSection.bind(this);
//     this.didChooseFinish = this.didChooseFinish.bind(this);
  }
  
  didSelectPerson = (person) => {
    var self = this;
    
    self.state.shareChain.push(person);
    //self.goToNextSection();
    self.forceUpdate();
    
    setTimeout(function() {
      self.goToNextSection();
    }, 1500);
    
  }
  
  didChoosePromptOption = () => {
    var self = this;
    self.goToNextSection();
  }
  
  goToNextSection() {
    var nextSection = this.state.currentSection+1;
    
    if(nextSection >= this.state.sectionCount) {
      return;
    }
    
    this.setState({
        currentSection: nextSection,
    });
    
  }
  
  componentDidMount() {
    
  }
  
  render() {
    
    const { currentSection, people, shareChain } = this.state;
    
    var self = this;
    
    return (
      
      <div className="photo-journey">
        <div className="photo-journey__viewport">

          <motion.div className="photo-journey__content" 
            initial="start"
            animate={(currentSection > 0) ? 'section'+currentSection : 'start'}
            variants={contentTransition}
            custom={currentSection}
          >
            
            <Touchpoint 
                onSelectPerson={self.didSelectPerson} 
                people={people} 
                device="selfie-phone" 
                shareChain={[]}
                isActive={currentSection === 0}
                wasActive={currentSection > 0} />
            
            <Touchpoint 
                onSelectPerson={self.didSelectPerson} 
                people={people} 
                isActive={currentSection === 1} 
                wasActive={currentSection > 1}
                toPerson={(shareChain.length > 0) ? shareChain[0] : null}
                shareChain={shareChain} />
            
            <Touchpoint 
                onSelectPerson={self.didSelectPerson} 
                people={people} 
                shareChain={shareChain}
                isActive={currentSection === 2} 
                toPerson={(shareChain.length > 1) ? shareChain[1] : null}
                wasActive={currentSection > 2} />
            
            <TouchpointFinish 
                onSelectNext={self.didChoosePromptOption} 
                onSelectYes={self.didChoosePromptOption} 
                onSelectNo={self.didChoosePromptOption} 
                people={people} 
                toPerson={(shareChain.length > 2) ? shareChain[2] : null}
                shareChain={shareChain}
                isActive={currentSection === 3} />
                
            <TouchpointFinish 
                onSelectNext={self.didChoosePromptOption} 
                onSelectYes={self.didChoosePromptOption} 
                onSelectNo={self.didChoosePromptOption} 
                people={people} 
                toPerson={(shareChain.length > 3) ? shareChain[3] : null}
                shareChain={shareChain}
                isActive={currentSection === 4} />
                
            <Summary 
                people={people} 
                shareChain={shareChain}
                onSelectFinish={self.props.onFinish} 
                isActive={currentSection === 5} />
            
          </motion.div>
          
        </div>
      </div>
      
    )
  }
};


export default withRouter(PhotoJourney);
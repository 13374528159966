import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import GameMenu from './GameMenu';
import WhoCanHelp from './who-can-help/WhoCanHelp';
import ShareThatPhoto from './share-that-photo/ShareThatPhoto';
import TechnologyPlan from './technology-plan/TechnologyPlan';

class App extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      selectedScenario: null,
      isChangingRoutes: false,
    };
    
  }
  
  componentDidMount() {
    //this.setState({isChangingRoutes: false})
  }
  
  didSelectScenario = (scenario) => {
    var self = this;
    
    self.setState({isChangingRoutes: true})
     
/*
    setTimeout(function() {
      self.state.history.push('/who-should-i-tell')
      self.setState({isChangingRoutes: false})
    }, 1000);
*/
  }
  
  render() {

    return (
      
    <div className="App">

      <BrowserRouter>
        
        <Switch>
          <Redirect from="/home" to="/" />
          
          <Route 
            exact 
            path="/" 
            render={() => (
              <GameMenu />
            )
          } />
          
          <Route 
            path="/who-can-help" 
            render={() => (
              <WhoCanHelp />
            )
          } />
          
          <Route 
            path="/share-that-photo" 
            render={() => (
              <ShareThatPhoto />
            )
          } />
          
          <Route 
            path="/technology-plan" 
            render={() => (
              <TechnologyPlan />
            )
          } />
          
        </Switch>

      </BrowserRouter>
      
    </div>
      
    )
  }
};


export default App;

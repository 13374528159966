import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom'
import Header from '../Header';
import PhotoJourney from './PhotoJourney';

import { motion } from "framer-motion";
import { fadeInTransition } from './../../data/Transitions';

class ShareThatPhoto extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      isChangingRoutes: false,
      isShowingModal: false,
    };
    
  }
  
  componentDidMount() {

  }
  
  didSelectBrandLogo = () => {
    var self = this;
    self.setState({isChangingRoutes: true})
     
    setTimeout(function() {
      self.props.history.push('/')
      self.setState({isChangingRoutes: false})
    }, 1000);
  }
  
  didSelectBack = () => {
    var self = this;
    self.setState({isChangingRoutes: true})
     
    setTimeout(function() {
      //self.props.history.push('/')
      self.props.history.goBack();
      self.setState({isChangingRoutes: false})
    }, 1000);
  }
  
  didSelectFinish = () => {
    var self = this;
    self.setState({isChangingRoutes: true, isShowingModal: false})
     
    setTimeout(function() {
      self.props.history.push('/')
      self.setState({isChangingRoutes: false})
    }, 1000);
  }
  
  render() {
    
    const { isChangingRoutes } = this.state;

    var self = this,
        mainClass = 'main--'+this.props.location.pathname.split("/").pop();

    return (
      
      <div>
          
          <Header onSelectBack={self.didSelectBack} onSelectBrandLogo={self.didSelectBrandLogo} />
  
          <motion.main id="main" className={mainClass} initial="unmounted" animate={(isChangingRoutes) ? 'unmounted' : 'mounted'} variants={fadeInTransition}>
  
            <Switch>
              
              <Route 
                exact 
                path="/share-that-photo" 
                render={() => (
                  <PhotoJourney onFinish={self.didSelectFinish} />
                )
              } />
              
            </Switch>
            
          </motion.main>

      </div>
      
    )
  }
};


export default withRouter(ShareThatPhoto);

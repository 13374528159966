import React from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { withRouter } from 'react-router-dom'
import { ReactSVG } from 'react-svg';
import { TechnologyPlanGameData } from './../../data/GameData';

class AddPeople extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      currentName: '',
      isUsingNameForm: false,
      colours: TechnologyPlanGameData.colours,
    };
    
    this.handleNameChange = this.handleNameChange.bind(this);
    this.onNameFocus = this.onNameFocus.bind(this);
    this.onNameBlur = this.onNameBlur.bind(this);
    this.didSubmitPersonName = this.didSubmitPersonName.bind(this);
    this.colourForIndex = this.colourForIndex.bind(this);
  }
  
  componentDidMount() {
    
  }
  
  handleNameChange(event) {
    this.setState({currentName: event.target.value});
  }
  
  didSubmitPersonName(e) {
    e.preventDefault();
    
    if(!this.state.currentName.length || this.isCurrentNameInUse()) {
      return;
    }
    
    this.props.people.push(this.state.currentName);
    
    this.setState({currentName: ''});
  }
  
  onNameFocus() {
    this.setState({isUsingNameForm: true});
  }
  
  onNameBlur() {
    this.setState({isUsingNameForm: false});
  }

  isCurrentNameInUse() {
    
    const { currentName } = this.state;
    const { people } = this.props;
    
    var existingName = people.findIndex(person => currentName.toLowerCase() === person.toLowerCase());
    
    return (existingName >= 0);
  }
  
  tooltipMessage() {
    
    if(this.isCurrentNameInUse()) {
      return "You've already used this name!";
    }

    return null;
  }
  
  colourForIndex(index) {

    while(index >= this.state.colours.length) {
      index -= this.state.colours.length;
    }
    
    return this.state.colours[index];
  }
  
  render() {
    
    const { isUsingNameForm, currentName } = this.state;
    const { people } = this.props;
    
    var self = this, 
        listClasses = ['add-people__right'],
        formClasses = ['add-people__left__form'];
        
        formClasses.push(['add-people__left__form--'+self.colourForIndex(people.length)]);
        
        if(isUsingNameForm) {
          formClasses.push('add-people__left__form--focus');
        }
        
        if(self.isCurrentNameInUse() || !currentName.length) {
          formClasses.push('add-people__left__form--disabled');
        }
        
        if(!people.length) {
          listClasses.push('people__right--disabled');
        }

    return (
      
      <div className="container">
        <div className="add-people">
          <div className="add-people__left">
            
            <div className="add-people__left__header">
              <h1 className="title">Who’s in your house?</h1>
              <p className="subtitle">Type in the name of everyone in your home. When you’re done, hit next.</p>
            </div>
            
            <form className={formClasses.join(' ')} onSubmit={self.didSubmitPersonName}>
              <div className='input-wrapper'>
                <input 
                  autoComplete="off"
                  onChange={self.handleNameChange} 
                  value={self.state.currentName} 
                  onFocus={ self.onNameFocus } 
                  onBlur={ self.onNameBlur } 
                  type="text" placeholder="Name" name="name" />
              </div>
              <button type="submit" className="button button--wide button--wide--add"><span>Add</span></button>
              
              <AnimatePresence>
                {(self.tooltipMessage()) && (
                  <motion.p 
                    className="form-tooltip"
                    initial={{ opacity: 0, y: 5 }}
                    animate={{opacity: 1, y: 0, transition: { duration: 0.3, ease: 'backOut' }}}
                    exit={{ opacity: 0, y: 5, transition: { duration: 0.2, ease: 'easeIn' }}}
                  >
                    { self.tooltipMessage() }
                  </motion.p>
                )}
              </AnimatePresence>
              
            </form>
            
          </div>
          <div className={listClasses.join(' ')}>
            
            <div className="add-people__right__list">
              
              
              {
                people.length ?
                
                  <ul>
                    
                    {people.map((person, index) => {                  
                      return (
                        <AnimatePresence key={'motion-'+index}>
                          <motion.li 
                            key={index}
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{opacity: 1, scale: 1, transition: { duration: 0.3, ease: 'backOut' }}}
                            exit={{ opacity: 0, scale: 0, transition: { duration: 0.2, ease: 'easeIn' }}}
                          >
                            <div className={'icon icon--'+self.colourForIndex(index)}>
                              <ReactSVG src={require('../../images/icon-profile.svg')} />
                            </div>
                            <span>{ person }</span>
                          </motion.li>
                        </AnimatePresence>
                      )
                      
                    })}
                    
                  </ul>
              
                    :
              
                  <p className="no-people">Add people on the left and they will appear here.</p>
                  
              }


            </div>
            
            <div className="add-people__right__footer">
              <button onClick={(e) => self.props.onFinish(e)} type="submit" className="button button--wide button--wide--arrow-right"><span>Next</span></button>
            </div>
            
          </div>
        </div>
      </div>
      
    )
  }
};


export default withRouter(AddPeople);
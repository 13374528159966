import React from 'react';
import { withRouter } from 'react-router-dom'
import { motion } from "framer-motion";

class Prompt extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {

    };
  }
  
  componentDidMount() {

  }
  
  render() {
    
    return (
      
      <motion.div 
        className="prompt-modal-wrapper"
        initial={{ opacity: 0, scale: 0, }}
        animate={{opacity: 1, scale: 1, transition: { type: 'spring', stiffness: 200, damping: 10}}}
        exit={{ opacity: 0, scale: 0, }}
      >
        
        <div className="prompt-modal">
          <div className="prompt-modal__header">
            <h1>Let’s make some rules!</h1>
          </div>
          
          <div className="prompt-modal__body">
            <p>Everybody needs rules, even parents. But there is one rule that is really important: <strong>Young children should always be supervised when using technology at home.</strong></p>
            <p>Now let’s create and agree on some rules together...</p>
          </div>
          
          <div className="prompt-modal__right__footer">
            <button onClick={this.props.onSelectFinish} className="button button--wide button--wide--arrow-right"><span>Next</span></button>
          </div>
        </div>
        
      </motion.div>
      
    )
  }
};


export default withRouter(Prompt);

import React from 'react';
import { ReactSVG } from 'react-svg';
import { withRouter } from 'react-router-dom';
import { motion } from "framer-motion";
import { fadeInTransition, planBodyTransition, planItemTransition } from './../data/Transitions';

import Header from './Header';

import gameIcon1 from '../images/game-1.png';
import gameIcon2 from '../images/game-2.png';
import gameIcon3 from '../images/game-3.png';

class GameMenu extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      isChangingRoutes: false,
      isMounted: false,
    };
    
  }
  
  componentDidMount() {
    this.setState({isMounted: true})
  }

  didSelectBrandLogo = () => {
    var self = this;
    self.setState({isChangingRoutes: true})
     
    setTimeout(function() {
      self.setState({isChangingRoutes: false})
      self.props.history.push('/')
    }, 1000);
  }
  
  didSelectBack = () => {
    var self = this;
    self.setState({isChangingRoutes: true})
     
    setTimeout(function() {
      self.props.history.goBack();
      //self.props.history.push('/who-can-help')
      self.setState({isChangingRoutes: false})
    }, 1000);
  }
  
  onSelectWhoCanHelp = () => {
    var self = this;
    self.setState({isChangingRoutes: true})
     
    setTimeout(function() {
      self.setState({isChangingRoutes: false})
      self.props.history.push('/who-can-help')
    }, 500);
  }
  
  onSelectShareThatPhoto = () => {
    var self = this;
    self.setState({isChangingRoutes: true})
     
    setTimeout(function() {
      self.setState({isChangingRoutes: false})
      self.props.history.push('/share-that-photo')
    }, 500);
    
  }
  
  onSelectTechnologyPlan = () => {
    var self = this;
    self.setState({isChangingRoutes: true})
     
    setTimeout(function() {
      self.setState({isChangingRoutes: false})
      self.props.history.push('/technology-plan')
    }, 500);
  }
  
  render() {
    
    const { isChangingRoutes, isMounted } = this.state;

    var self = this;

    return (
      
      <div>
        
        <Header onSelectBack={self.didSelectBack} onSelectBrandLogo={self.didSelectBrandLogo} />
        
        <motion.main id="main" className="main--game-menu" initial="unmounted" animate={(isChangingRoutes) ? 'unmounted' : 'mounted'} variants={fadeInTransition}>

          <div className="container">
            <motion.div 
              className="game-menu"
              variants={planBodyTransition}
              initial="unmounted"
              animate={isMounted ? "mounted" : "unmounted"}
            >
              
              <motion.div className="game-menu__header" variants={planItemTransition} initial="unmounted">
                <h1>{ "Let's Play" }</h1>
                <p>Designed to be played together with a parent or carer, these simple activities will teach children ways they should be acting and behaving to remain safe online.</p>
              </motion.div>
              
              <div className="game-menu__detail">
                <ReactSVG src={require('../images/detail-play-flipped.svg')} />
              </div>
              
              <div className="game-menu__circles">
                
                <div className="circle-1">
                  <ReactSVG src={require('../images/circle-rings.svg')} />
                </div>
                
                <div className="circle-2">
                  <ReactSVG src={require('../images/dashed-circle.svg')} />
                </div>
                
                <div className="circle-3">
                  <ReactSVG src={require('../images/striped-circle.svg')} />
                </div>
                
              </div>
              
              
              <motion.article className="game-menu__game game-menu__game--who-can-help" variants={planItemTransition} initial="unmounted">
                
                <div className="game-menu__game__image">
                  <img src={gameIcon1} alt="Who Can Help?" />
                </div>
                
                <div className="game-menu__game__body">
                  <h2>Who Can Help?</h2>
                  <p>Create and print a handy technology usage plan for the whole household.</p>
                  <button className="button" onClick={this.onSelectWhoCanHelp}><span>Play</span></button>
                </div>
                
                <div className="game-menu__game__detail">
                  <ReactSVG src={require('../images/detail-play.svg')} />
                </div>
                
              </motion.article>
              
              
              <motion.article className="game-menu__game game-menu__game--share-that-photo" variants={planItemTransition} initial="unmounted">
              
                <div className="game-menu__game__image">
                  <img src={gameIcon2} alt="Share That Photo Game" />
                </div>
                
                <div className="game-menu__game__body">
                  <h2>Share That Photo</h2>
                  <p>Create and print a handy technology usage plan for the whole household.</p>
                  <button className="button" onClick={this.onSelectShareThatPhoto}><span>Play</span></button>
                </div>
                
                <div className="game-menu__game__detail">
                  <ReactSVG src={require('../images/detail-play.svg')} />
                </div>
                
              </motion.article>
              
              <motion.article className="game-menu__game game-menu__game game-menu__game--tech-plan" variants={planItemTransition} initial="unmounted">
              
                <div className="game-menu__game__image">
                  <img src={gameIcon3} alt="Family Technology Plan" />
                </div>
                
                <div className="game-menu__game__body">
                  <h2>Family Technology Plan</h2>
                  <p>Create and print a handy technology usage plan for the whole household.</p>
                  <button className="button" onClick={this.onSelectTechnologyPlan}><span>Play</span></button>
                </div>
                
                <div className="game-menu__game__detail">
                  <ReactSVG src={require('../images/detail-play.svg')} />
                </div>
                
              </motion.article>
              
            </motion.div>
          </div>
          
        </motion.main>

      </div>
      
    )
  }
};


export default withRouter(GameMenu);

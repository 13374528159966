import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom'
import { WhoCanHelpGameData } from './../../data/GameData';
import Header from '../Header';
import ScenarioPicker from './ScenarioPicker';
import PersonPicker from './PersonPicker';
import ModalMenu from './ModalMenu';
import Summary from './Summary';

import { motion, AnimatePresence } from "framer-motion";
import { fadeInTransition } from './../../data/Transitions';

class WhoCanHelp extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      isShowingModal: false,
      scenarios: WhoCanHelpGameData.scenarios,
      selectedScenario: null,
      isChangingRoutes: false,
    };
    
  }
  
  componentDidMount() {

  }
  
  didSelectBrandLogo = () => {
    var self = this;
    self.setState({isChangingRoutes: true})
     
    setTimeout(function() {
      self.setState({isChangingRoutes: false})
      self.props.history.push('/')
    }, 1000);
  }
  
  didSelectBack = () => {
    var self = this;
    self.setState({isChangingRoutes: true})
     
    setTimeout(function() {
      self.setState({isChangingRoutes: false})
      //self.props.history.goBack();
      self.props.history.push('/who-can-help')
    }, 1000);
  }
  
  didSelectMenu = () => {
    var self = this;
    self.setState({isShowingModal: true})
  }
  
  didSelectCloseMenu = () => {
    var self = this;
    self.setState({isShowingModal: false})
  }
  
  didSelectFinish = () => {
    var self = this;
    self.setState({isChangingRoutes: true, isShowingModal: false})
     
    setTimeout(function() {
      self.setState({isChangingRoutes: false})
      self.props.history.push('/who-can-help/summary')
    }, 1000);
  }
  
  didSelectScenario = (scenario) => {
    var self = this;
    self.setState({selectedScenario: scenario, isChangingRoutes: true, isShowingModal: false})
     
    setTimeout(function() {
      self.setState({isChangingRoutes: false});
      self.props.history.push('/who-can-help/'+scenario.slug);
    }, 1000);
    
  }
  
  render() {
    
    const { isChangingRoutes, scenarios, isShowingModal } = this.state;

    var self = this,
        path = this.props.location.pathname.split("/").pop(),
        mainClasses = ['main--'+path];
        
        if(!path.length) {
          mainClasses.push('main--who-can-help-home');
        }
        
        if(isShowingModal) {
          mainClasses.push('main--inactive');
        }

    return (
      
      <div>
          
          <Header onSelectBack={self.didSelectBack} onSelectFinish={self.didSelectFinish} onSelectMenu={self.didSelectMenu} onSelectBrandLogo={self.didSelectBrandLogo} />
  
          <motion.main id="main" className={mainClasses.join(' ')} initial="unmounted" animate={(isChangingRoutes) ? 'unmounted' : 'mounted'} variants={fadeInTransition}>
  
            <Switch>
              
              <Route 
                exact 
                path="/who-can-help" 
                render={() => (
                  <ScenarioPicker onSelectScenario={self.didSelectScenario} />
                )
              } />
              
              <Route 
                path="/who-can-help/summary" 
                render={() => (
                  <Summary />
                )
              } />
              
              <Route 
                path="/who-can-help/:slug" 
                render={() => (
                  <PersonPicker />
                )
              } />
              
            </Switch>
            
          </motion.main>
          
          <AnimatePresence>
            {isShowingModal && (
              <ModalMenu scenarios={scenarios} onSelectClose={self.didSelectCloseMenu} onSelectFinish={self.didSelectFinish} onSelectScenario={self.didSelectScenario} />
            )}
          </AnimatePresence>

      </div>
      
    )
  }
};


export default withRouter(WhoCanHelp);

import React from 'react';
import { withRouter } from 'react-router-dom'
import { motion } from "framer-motion";
import { ReactSVG } from 'react-svg';
import { menuItemImageTransition, menuItemLabelTransition } from './../../data/Transitions';

class ModalMenu extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {

    };
  }
  
  componentDidMount() {

  }
  
  render() {
    
    const { scenarios } = this.props;

    var self = this,
        scenariosFiltered = scenarios.filter((scenario) => {
          return (scenario.summaryOnly !== true);
        });

    return (
      
      <motion.nav 
        className="picker-menu-modal"
        initial={{ opacity: 0, scale: 0, }}
        animate={{opacity: 1, scale: 1, transition: { type: 'spring', stiffness: 200, damping: 10}}}
        exit={{ opacity: 0, scale: 0, }}
      >
        
        <div className="picker-menu-modal__body">
          <button onClick={this.props.onSelectClose} className="picker-menu-modal__close"><span>Close</span></button>
          
          <ul className="picker-menu">
            
            {scenariosFiltered.map(scenario => {

              return (
                <li key={scenario.id}>
                  <div className={"picker-button " + (scenario.completed ? 'picker-button--completed' : '')}>
                    <div className="picker-button__image">
                      <div variants={menuItemImageTransition}>
                        <ReactSVG src={require('../../images/'+scenario.icon)} />
                      </div>
                    </div>
                    <h2 variants={menuItemLabelTransition} initial="unmounted" className="picker-button__label"><span>{ scenario.name }</span></h2>
                      
                    {/* 
                      For accessibility, adding a separate button that covers this element
                      to ensure it is compatible with mouse, touch, keyboard and screen readers
                     */}
                     
                    <button onClick={(e) => self.props.onSelectScenario(scenario)} className="picker-button__button"><span className="sr-only">Select</span></button>
                  </div>
                </li>
              )
              
            })}
            
          </ul>
          
          <div className="picker-menu-modal__footer">
            <button onClick={this.props.onSelectFinish} className="button button--orange picker-menu-modal__finish"><span>Finish</span></button>
          </div>
          
        </div>
        
      </motion.nav>
      
    )
  }
};


export default withRouter(ModalMenu);

import React from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { withRouter } from 'react-router-dom'

export const photoTransition  = {
  hidden: { x: '0%', y: '0%', opacity: 0, scale: 0.3, rotate: 0, transition: { ease: 'backIn', duration: 2} },
  visible: { x: '0%', y: '0%', opacity: 1, scale: 1, rotate: 0, transition: { delay: 1, ease: 'backOut', duration: 2} },
}

const positions = [
  {x:'0%', y:'-200%'},
  {x:'150%', y:'-150%'},
  {x:'200%', y:'0%'},
  {x:'150%', y:'150%'},
  {x:'0%', y:'200%'},
  {x:'-150%', y:'150%'},
  {x:'-200%', y:'0%'},
  {x:'-150%', y:'-150%'},
];

export const personTransition  = {
  hidden: { x: '0%', y: '0%', transition: { ease: 'backInOut', duration: 2} },
  visible: i => ({
    x: positions[i-1].x, 
    y: positions[i-1].y,
    transition: {
      delay: i * 0.1,
      duration: 2, 
      type: 'spring', 
      stiffness: 200, 
      damping: 20,
    },
  }),
}


class TouchpointFinish extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      toPerson: null,
      isShowingFinalMessage: false,
    };
    
    this.toggleDebug = this.toggleDebug.bind(this);
    
  }
  
  componentDidUpdate(prevProps, prevState) {
    var self = this;
    
    if (!prevProps.isActive && this.props.isActive) {
      
      // Last person on the chain must be the recipient, so adding that to state
      self.setState({
        toPerson: (self.props.shareChain.length) ? self.props.shareChain[self.props.shareChain.length-1] : null,
      });
      
      setTimeout(function() {
        self.setState({isShowingFinalMessage: true});
      }, 2000);
    }
    
  }
  
  componentDidMount() {
//     var self = this;
  }
  
  toggleDebug() {
    this.setState({isShowingFinalMessage: !this.state.isShowingFinalMessage});
  }
  
  getMessageTitle() {
    
    const { toPerson } = this.state;
    
    var message = '';
    
    if(toPerson != null) {
      message += toPerson.name+' decides to share it on social media. ';
    }
    
    return message;
  }
  
  render() {
    
    const { toPerson, isShowingFinalMessage } = this.state;
    const { people, isActive } = this.props;
    
    var self = this,
        wrapperClasses = ['photo-journey__content__section'], 
        deviceClasses = ['device', 'device--social-media'];
    
    if(isActive) {
      wrapperClasses.push('photo-journey__content__section--active');
    }
    
    if(isShowingFinalMessage) {
      deviceClasses.push('device--social-media--showing-message');
    }
    
    return (
      
      <div className={wrapperClasses.join(' ')}>
        
        <div className="photo-journey__content__section__device">
          
          <div className={ deviceClasses.join(' ') }>

            <div className="device__people">
              {people.map((person, index) => {
                
                var classes = ['person-profile', 'person-profile--person'+person.id],
                    wrapperClasses = ['person-profile-wrapper'];
                
                if(toPerson != null && (toPerson.id === person.id)) {
                  classes.push('person-profile--selected');
                  wrapperClasses.push('person-profile-wrapper--selected');
                }
                
                return (
                  <motion.div className={ wrapperClasses.join(' ') }
                    key={person.id}
                    initial="hidden" 
                    animate={(isShowingFinalMessage) ? 'visible' : 'hidden'}
                    variants={personTransition}
                    custom={person.id}
                  >
                  <div className={ classes.join(' ') }></div>
                  </motion.div>
                )
              })}
            </div>
            
            <motion.div className="device__photo" 
              initial="hidden" 
              animate={(isShowingFinalMessage) ? 'visible' : 'hidden'}
              variants={photoTransition}
            ><div className="photo"></div></motion.div>

          </div>

        </div>
        
        <div className="photo-journey__content__section__options">
          
          <AnimatePresence>
            {(isShowingFinalMessage) && (
              <motion.div 
                className="message-prompt"
                initial={{ opacity: 0, }}
                animate={{opacity: 1, transition: { delay: 3, duration: 0.5, ease: 'easeIn' }}}
                exit={{ opacity: 0, }}
              >
                <h1>{ self.getMessageTitle() } <em>What could that mean?</em></h1>
                
                <div className="message-prompt__buttons">
                  <button onClick={self.props.onSelectNext} className="button button--wide button--wide--arrow-right"><span>Next</span></button>
                </div>
                
              </motion.div>
            )}
          </AnimatePresence>
          
        </div>
        
      </div>
      
    )
  }
};


export default withRouter(TouchpointFinish);
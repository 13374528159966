import React from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { ReactSVG } from 'react-svg';

class RuleOption extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      isShowingOptions: false,
    };
    
    this.onToggleOptions = this.onToggleOptions.bind(this);
  }
  
  onToggleOptions(e) {
    e.preventDefault();
    
    this.setState({
      isShowingOptions: !this.state.isShowingOptions,
    })
  }
  
  onSelectOptionAtIndex(index) {
    var self = this;
    
    self.setState({
      isShowingOptions: false,
    });
    
    setTimeout(function() {
      self.props.onSelectOptionAtIndex(index);
    }, 300);

  }
  
  componentDidMount() {
    //this.props.children
  }
  
  _renderIcon(value) {
    
    const { options } = this.props;
    
    var selectedOption = options.find((item) => {
          return item.name === value;
        });
		
		if(!selectedOption || !selectedOption.icon) {
  		return ('');
		}
    
    return (
      <span className="icon">
        <ReactSVG src={require('../../images/'+selectedOption.icon)} />
      </span>
    )
  }

  render() {

    const { isShowingOptions } = this.state;
    const { value, options, colour = 'default' } = this.props;

    var self = this, 
        wrapperClasses = ['rule-option-wrapper', 'key-colour-'+colour],
        classes = ['rule-option', 'rule-option--'+colour];
    
    return (

      <span className={wrapperClasses.join(' ')}>
        <span className={classes.join(' ')}>
          <button className="rule-option__button" onClick={(e) => self.onToggleOptions(e)}>
            {this._renderIcon(value)}
            <span>{ value }</span>
          </button>

          <AnimatePresence>
            {(isShowingOptions) && (
              <motion.ul 
                initial={{ opacity: 0, y: '-40%' }}
                animate={{opacity: 1, y: '-50%', transition: { duration: 0.3, ease: 'backOut' }}}
                exit={{ opacity: 0, y: '-40%', transition: { duration: 0.2, ease: 'easeIn' }}}
              >
                
                {options.map((option, index) => {
                  return (
                    <li key={index} className={(option.name === value) ? 'selected' : ''}>
                      
                      {(option.icon && option.icon.length) && (
                        <div className="rule-option__icon">
                          <ReactSVG src={require('../../images/'+option.icon)} />
                        </div>
                      )}
                      <button onClick={(e) => {e.preventDefault(); self.onSelectOptionAtIndex(index)}}>{ option.name }</button>
                    </li>
                  )
                })}
                
              </motion.ul>
            )}
          </AnimatePresence>
          
        </span>
        
        {(isShowingOptions) && (
          <span onClick={(e) => self.onToggleOptions(e)} className="rule-option-wrapper__overlay"></span>
        )}
        
      </span>
      
    )
  }
};

export default RuleOption;
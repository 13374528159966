
export const WhoCanHelpGameData = {
    heading:    "Oh No! Something is wrong, can you help?",
    scenarios:  [
      {
        id: 1,
        name: "I'm Lost",
        slug: "lost",
        icon: "icon-lost.svg",
        question: "A child is lost and can’t find their parent or carer. Who can help?",
        completed: false,
        summaryOnly: false,
        options: [
          {
            id: 1,
            name: "Police",
            icon: "icon-police.svg",
            iconSelected: "icon-police-selected.svg",
            prompt: "Great idea! Police officers can help you in any situation. But if you can’t find one, who else can help?",
            correct: false,
          },{
            id: 2,
            name: "Parents/Carers",
            icon: "icon-parents.svg",
            iconSelected: "icon-parents-selected.svg",
            prompt: "Absolutely! But who should you tell if you can't find your parent or carer?",
            correct: false,
          },{
            id: 3,
            name: "Teacher",
            icon: "icon-teacher.svg",
            iconSelected: "icon-teacher-selected.svg",
            prompt: "Great idea! But a firefighter might be hard to find. Who else can help?",
            correct: true,
          },{
            id: 4,
            name: "Firefighter",
            icon: "icon-firefighter.svg",
            iconSelected: "icon-firefighter-selected.svg",
            prompt: "Great idea! But a firefighter might be hard to find. Who else can help?",
            correct: false,
          },{
            id: 5,
            name: "Grandparents",
            icon: "icon-grandparents.svg",
            iconSelected: "icon-grandparents-selected.svg",
            prompt: "Absolutely! But if you can’t find your grandparent, who else can help?",
            correct: false,
          },{
            id: 6,
            name: "Older Siblings",
            icon: "icon-siblings.svg",
            iconSelected: "icon-siblings-selected.svg",
            prompt: "Absolutely! But if you can’t find your older sibling, who else can help?",
            correct: false,
          },
        ],
      },{
        id: 2,
        name: "Fire!",
        slug: "fire",
        icon: "icon-fire.svg",
        question: "A family sees a house on fire. Who can help?",
        completed: false,
        summaryOnly: false,
        options: [
          {
            id: 1,
            name: "Police",
            icon: "icon-police.svg",
            iconSelected: "icon-police-selected.svg",
            prompt: "Absolutely! Police officers can help in an emergency and call a firefighter. ",
            correct: false,
          },{
            id: 2,
            name: "Parents/Carers",
            icon: "icon-parents.svg",
            iconSelected: "icon-parents-selected.svg",
            prompt: "Great idea! Your parent or carer can get help from someone who can put out a fire.",
            correct: false,
          },{
            id: 3,
            name: "Teacher",
            icon: "icon-teacher.svg",
            iconSelected: "icon-teacher-selected.svg",
            prompt: "Good thinking! A teacher can get help from someone who can put out a fire.",
            correct: false,
          },{
            id: 4,
            name: "Firefighter",
            icon: "icon-firefighter.svg",
            iconSelected: "icon-firefighter-selected.svg",
            prompt: "Yes! A firefighter has the right equipment and skills to put out a fire.",
            correct: true,
          },{
            id: 5,
            name: "Grandparents",
            icon: "icon-grandparents.svg",
            iconSelected: "icon-grandparents-selected.svg",
            prompt: "Great idea! A grandparent can get help from someone who can put out a fire.",
            correct: false,
          },{
            id: 6,
            name: "Older Siblings",
            icon: "icon-siblings.svg",
            iconSelected: "icon-siblings-selected.svg",
            prompt: "Good thinking! An older sibling can get help from someone who can put out a fire.",
            correct: false,
          },
        ],
      },{
        id: 3,
        name: "Pop-Up",
        slug: "popup",
        icon: "icon-ad.svg",
        question: "A child is using technology and things happen they aren’t used to. Who can help?",
        completed: false,
        summaryOnly: false,
        options: [
          {
            id: 1,
            name: "Police",
            icon: "icon-police.svg",
            iconSelected: "icon-police-selected.svg",
            prompt: "Who else can help? Police officers can help in any situation but might not be nearby. ",
            correct: false,
          },{
            id: 2,
            name: "Parents/Carers",
            icon: "icon-parents.svg",
            iconSelected: "icon-parents-selected.svg",
            prompt: "Yes! Your parent or carer know how to fix a problem with technology if you don’t know what is happening.",
            correct: true,
          },{
            id: 3,
            name: "Teacher",
            icon: "icon-teacher.svg",
            iconSelected: "icon-teacher-selected.svg",
            prompt: "Good thinking! If a teacher is nearby, they can help to fix a problem with technology.",
            correct: false,
          },{
            id: 4,
            name: "Firefighter",
            icon: "icon-firefighter.svg",
            iconSelected: "icon-firefighter-selected.svg",
            prompt: "Who else can help? Firefighters can help in any situation but might not be nearby. ",
            correct: false,
          },{
            id: 5,
            name: "Grandparents",
            icon: "icon-grandparents.svg",
            iconSelected: "icon-grandparents-selected.svg",
            prompt: "Good thinking! Your grandparent can help to fix a problem with technology.",
            correct: false,
          },{
            id: 6,
            name: "Older Siblings",
            icon: "icon-siblings.svg",
            iconSelected: "icon-siblings-selected.svg",
            prompt: "Great idea! Your older sibling can help to fix a problem with technology.",
            correct: false,
          },
        ],
      },{
        id: 4,
        name: "Watch Videos",
        slug: "watch-videos",
        icon: "icon-youtube.svg",
        question: "A child wants to watch a video. Who can help?",
        completed: false,
        summaryOnly: false,
        options: [
          {
            id: 1,
            name: "Police",
            icon: "icon-police.svg",
            iconSelected: "icon-police-selected.svg",
            prompt: "Who else can help? Police officers can help in any situation but might not be nearby. ",
            correct: false,
          },{
            id: 2,
            name: "Parents/Carers",
            icon: "icon-parents.svg",
            iconSelected: "icon-parents-selected.svg",
            prompt: "Yes! Your parent or carer should talk to you about which videos are OK to watch.",
            correct: true,
          },{
            id: 3,
            name: "Teacher",
            icon: "icon-teacher.svg",
            iconSelected: "icon-teacher-selected.svg",
            prompt: "Good thinking! If a teacher is nearby, they will know which videos are OK to watch.",
            correct: false,
          },{
            id: 4,
            name: "Firefighter",
            icon: "icon-firefighter.svg",
            iconSelected: "icon-firefighter-selected.svg",
            prompt: "Who else can help? Firefighters can help in any situation but might not be nearby. ",
            correct: false,
          },{
            id: 5,
            name: "Grandparents",
            icon: "icon-grandparents.svg",
            iconSelected: "icon-grandparents-selected.svg",
            prompt: "Great idea! Your grandparent will know which videos are OK to watch.",
            correct: false,
          },{
            id: 6,
            name: "Older Siblings",
            icon: "icon-siblings.svg",
            iconSelected: "icon-siblings-selected.svg",
            prompt: "Good thinking! Your older sibling can check with your parent or carer which videos are OK to watch.",
            correct: false,
          },
        ],
      },{
        id: 6,
        name: "Choose Games",
        slug: "choose-games",
        icon: "icon-game.svg",
        question: "A child wants to play video game. Who can help?",
        completed: false,
        summaryOnly: false,
        options: [
          {
            id: 1,
            name: "Police",
            icon: "icon-police.svg",
            iconSelected: "icon-police-selected.svg",
            prompt: "Who else can help? Police officers can help in any situation but might not be nearby. ",
            correct: false,
          },{
            id: 2,
            name: "Parents/Carers",
            icon: "icon-parents.svg",
            iconSelected: "icon-parents-selected.svg",
            prompt: "Yes! Your parent or carer can help you choose a video game that is right for you to play.",
            correct: true,
          },{
            id: 3,
            name: "Teacher",
            icon: "icon-teacher.svg",
            iconSelected: "icon-teacher-selected.svg",
            prompt: "Absolutely! If a teacher is nearby, they can help you choose a video game that is right for you to play.",
            correct: false,
          },{
            id: 4,
            name: "Firefighter",
            icon: "icon-firefighter.svg",
            iconSelected: "icon-firefighter-selected.svg",
            prompt: "Who else can help? Firefighters can help in any situation but might not be nearby. ",
            correct: false,
          },{
            id: 5,
            name: "Grandparents",
            icon: "icon-grandparents.svg",
            iconSelected: "icon-grandparents-selected.svg",
            prompt: "Great idea! Your grandparent can help you choose a video game that is right for you to play.",
            correct: false,
          },{
            id: 6,
            name: "Older Siblings",
            icon: "icon-siblings.svg",
            iconSelected: "icon-siblings-selected.svg",
            prompt: "Good thinking! Your older sibling can check with your parent or carer which video games are right for you to play.",
            correct: false,
          },
        ],
      },{
        id: 7,
        name: "Scary",
        slug: "scary",
        icon: "icon-scary.svg",
        question: "A child sees something online that makes them feel frightened or uncomfortable. Who can help?",
        completed: false,
        summaryOnly: false,
        options: [
          {
            id: 1,
            name: "Police",
            icon: "icon-police.svg",
            iconSelected: "icon-police-selected.svg",
            prompt: "Absolutely! Police officers can help in any situation, including if you find scary or uncomfortable things online. If you can’t find one, who else can help?",
            correct: false,
          },{
            id: 2,
            name: "Parents/Carers",
            icon: "icon-parents.svg",
            iconSelected: "icon-parents-selected.svg",
            prompt: "Yes! Your parent or carer can help you feel safe and manage things you see online.",
            correct: true,
          },{
            id: 3,
            name: "Teacher",
            icon: "icon-teacher.svg",
            iconSelected: "icon-teacher-selected.svg",
            prompt: "Great idea! If a teacher is nearby, they can help you feel safe and manage things you see online. ",
            correct: false,
          },{
            id: 4,
            name: "Firefighter",
            icon: "icon-firefighter.svg",
            iconSelected: "icon-firefighter-selected.svg",
            prompt: "Who else can help? Firefighters can help in any situation but might not be nearby. ",
            correct: false,
          },{
            id: 5,
            name: "Grandparents",
            icon: "icon-grandparents.svg",
            iconSelected: "icon-grandparents-selected.svg",
            prompt: "Great idea! Your grandparent can help you feel safe and manage things you see online. ",
            correct: false,
          },{
            id: 6,
            name: "Older Siblings",
            icon: "icon-siblings.svg",
            iconSelected: "icon-siblings-selected.svg",
            prompt: "Good thinking! Your older sibling can help you feel safe and manage things you see online, or get help from your parent or carer. ",
            correct: false,
          },
        ],
      },
    ],
}

export const ShareThatPhotoGameData = {
    shareChain: [],
    people: [
      {
        id: 1,
        device: "laptop",
        name: "Ben",
        pronouns: ["him", "his"],
        relationship: "Brother",
      },{
        id: 2,
        device: "phone",
        name: "Kate",
        pronouns: ["her", "her"],
        relationship: "Ben's Friend",
      },{
        id: 3,
        device: "tablet",
        name: "Laura",
        pronouns: ["her", "her"],
        relationship: "Friend",
      },{
        id: 4,
        device: "laptop",
        name: "Teddy",
        pronouns: ["him", "his"],
        relationship: "Cousin",
      },{
        id: 5,
        device: "phone",
        name: "Rebecca",
        pronouns: ["her", "her"],
        relationship: "Yasmin",
      },{
        id: 6,
        device: "tablet",
        name: "Mei Lien",
        pronouns: ["her", "her"],
        relationship: "Grandma",
      },{
        id: 7,
        device: "laptop",
        name: "Omar",
        pronouns: ["him", "his"],
        relationship: "Friend",
      },{
        id: 8,
        device: "phone",
        name: "Nick",
        pronouns: ["him", "his"],
        relationship: "Ivan",
      },
    ],
}


export const TechnologyPlanGameData = {
    rules: [],
    colours: ['orange', 'purple', 'red', 'blue', 'green'],
    people: [],
    rulePrototype: {
      person: '',
      colour: 'orange',
      verb: 'can',
      device: 'phone',
      place: 'kitchen',
      time: 'evening',
    },
    verbOptions: [{name: 'can', icon: 'tech-icon-tick.svg'}, {name: 'can’t', icon: 'tech-icon-cross.svg'}],
    deviceOptions: [{name: 'phone', icon: 'tech-icon-phone.svg'}, {name:'tablet', icon: 'tech-icon-tablet.svg'}, {name:'computer', icon: 'tech-icon-computer.svg'}, {name:'game console', icon: 'tech-icon-game.svg'}, {name:'TV', icon: 'tech-icon-tv.svg'}],
    placeOptions: [{name: 'kitchen', icon: 'tech-icon-kitchen.svg'}, {name: 'bedroom', icon: 'tech-icon-bedroom.svg'}, {name: 'bathroom', icon: 'tech-icon-bathroom.svg'}, {name: 'living room', icon: 'tech-icon-living-room.svg'}, {name: 'school', icon: 'tech-icon-school.svg'}, {name: 'outside', icon: 'tech-icon-outside.svg'}, {name: 'inside', icon: 'tech-icon-inside.svg'}],
    timeOptions: [{name: 'any time', icon: 'icon-sun.svg'}, {name: 'morning', icon: 'icon-sunset.svg'}, {name: 'daytime', icon: 'icon-sun.svg'}, {name: 'afternoon', icon: 'icon-sun.svg'}, {name: 'evening', icon: 'icon-moon.svg'}, {name: 'dinnertime', icon: 'tech-icon-dinnertime.svg'}, {name: 'bedtime', icon: 'tech-icon-bedtime.svg'}],
}

/*
{
        person: 'John',
        colour: 'orange',
        verb: 'can',
        device: 'phone',
        place: 'kitchen',
        time: 'morning',
      },{
        person: 'John',
        colour: 'orange',
        verb: 'can',
        device: 'phone',
        place: 'kitchen',
        time: 'afternoon',
      },{
        person: 'Paul',
        colour: 'red',
        verb: 'can',
        device: 'phone',
        place: 'kitchen',
        time: 'daytime',
      },{
        person: 'Peter',
        colour: 'green',
        verb: 'can',
        device: 'phone',
        place: 'kitchen',
        time: 'bedtime',
      },{
        person: 'Sally',
        colour: 'purple',
        verb: 'can',
        device: 'phone',
        place: 'kitchen',
        time: 'afternoon',
      },
*/
import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom'
import Header from '../Header';

import { ReactSVG } from 'react-svg';
import { motion, AnimatePresence } from "framer-motion";
import { fadeInTransition } from './../../data/Transitions';
import { TechnologyPlanGameData } from './../../data/GameData';

import AddPeople from './AddPeople';
import AddRule from './AddRule';
import Summary from './Summary';
import Prompt from './Prompt';

class TechnologyPlan extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      isChangingRoutes: false,
      isShowingPrompt: false,
      timeOptions: TechnologyPlanGameData.timeOptions,
      people: TechnologyPlanGameData.people,
      rules: TechnologyPlanGameData.rules,
    };
    
  }
  
  componentDidMount() {
    
//     var path = this.props.location.pathname.split("/").pop();

    if(!this.state.people.length || !this.state.rules.length) {
      this.props.history.push('/technology-plan')
    }

  }
  
  didSelectBrandLogo = () => {
    var self = this;
    self.setState({isChangingRoutes: true})
     
    setTimeout(function() {
      self.setState({isChangingRoutes: false})
      self.props.history.push('/')
    }, 1000);
  }
  
  didSelectBack = () => {
    var self = this;
    self.setState({isChangingRoutes: true})
     
    setTimeout(function() {
      self.setState({isChangingRoutes: false});
      self.props.history.goBack();
    }, 1000);
  }
  
  didSelectFinishAddingNames = () => {
    var self = this;
    
    if(!this.state.people.length) {
      return;
    }
    
    self.setState({isShowingPrompt: true})
  }
  
  didSelectGoToAddRules = () => {
    var self = this;
        
    self.setState({isChangingRoutes: true, isShowingPrompt: false})
    
    setTimeout(function() {
      self.props.history.push('/technology-plan/add-rule')
      self.setState({isChangingRoutes: false})
    }, 500);
  }

  didSelectAddNewRule = () => {
    var self = this;
        
    self.setState({isChangingRoutes: true})
    
    setTimeout(function() {
      self.props.history.push('/technology-plan/add-rule')
      self.setState({isChangingRoutes: false})
    }, 500);
  }
  
  didSelectPrint = () => {
    // @todo print screen...
  }
  
  didSelectFinishRule = (rule) => {
    var self = this;
        
    self.setState({isChangingRoutes: true})
    
    setTimeout(function() {
      if(rule.person.length) {
        self.state.rules.push(rule);
      }
      self.props.history.push('/technology-plan/summary')
      self.setState({isChangingRoutes: false})
    }, 500);
  }
  
  didSelectSaveThenAddRule = (rule) => {
    var self = this;
    
    self.setState({isChangingRoutes: true})
    
    setTimeout(function() {
      if(rule.person.length) {
        self.state.rules.push(rule);
      }
      self.props.history.push('/technology-plan/add-rule')
      self.setState({isChangingRoutes: false})
    }, 500);
  }
  
  didSelectFinish = () => {
    var self = this;
    self.setState({isChangingRoutes: true, isShowingModal: false})
     
    setTimeout(function() {
      self.props.history.push('/')
      self.setState({isChangingRoutes: false})
    }, 1000);
  }
  
  render() {
    
    const { isChangingRoutes, people, rules, isShowingPrompt } = this.state;

    var self = this,
        mainClass = 'main--'+this.props.location.pathname.split("/").pop();

    return (
      
      <div>
          
          <Header onSelectBack={self.didSelectBack} onSelectBrandLogo={self.didSelectBrandLogo} />
  
          <motion.main id="main" className={mainClass} initial="unmounted" animate={(isChangingRoutes) ? 'unmounted' : 'mounted'} variants={fadeInTransition}>
  
            <Switch>
              
              <Route 
                exact 
                path="/technology-plan" 
                render={() => (
                  <AddPeople onFinish={self.didSelectFinishAddingNames} people={people}  />
                )
              } />
              
              <Route 
                exact 
                path="/technology-plan/add-rule" 
                render={() => (
                  <AddRule number={rules.length+1} onFinish={self.didSelectFinishRule} onAdd={self.didSelectSaveThenAddRule} people={people} rules={rules}  />
                )
              } />
              
              <Route 
                exact 
                path="/technology-plan/summary" 
                render={() => (
                  <Summary onAddNewRule={self.didSelectAddNewRule} onPrint={self.didSelectPrint} people={people} rules={rules}  />
                )
              } />
              
            </Switch>
            
            <div className="tech-plan-detail">
              <div className="pyramid"></div>
              
              <div className="dashed-circle">
                <ReactSVG src={require('../../images/dashed-circle.svg')} />
              </div>
              
              <div className="striped-circle">
                <ReactSVG src={require('../../images/striped-circle.svg')} />
              </div>
              
            </div>
            
          </motion.main>
          
          <AnimatePresence>
            {isShowingPrompt && (
              <Prompt onSelectFinish={self.didSelectGoToAddRules} />
            )}
          </AnimatePresence>

      </div>
      
    )
  }
};


export default withRouter(TechnologyPlan);

import React from 'react';
import { withRouter } from 'react-router-dom'
import { TechnologyPlanGameData } from './../../data/GameData';
import RuleOption from './RuleOption';
import cloneDeep from 'lodash/cloneDeep'

class AddRule extends React.Component {
  
  constructor(props) {
    super(props);
    
    var peopleOptions = [];
    
    props.people.forEach((person, index) => {
      peopleOptions.push({name: person, icon: 'icon-profile.svg'});
    })
    
    this.state = {
      newRule: this.makeNewRule(), 
      verbOptions: TechnologyPlanGameData.verbOptions,
      deviceOptions: TechnologyPlanGameData.deviceOptions,
      placeOptions: TechnologyPlanGameData.placeOptions,
      timeOptions: TechnologyPlanGameData.timeOptions,
      peopleOptions: peopleOptions,
      colours: TechnologyPlanGameData.colours,
    };

    this.didSelectPersonAtIndex = this.didSelectPersonAtIndex.bind(this);    
    this.didSelectVerbAtIndex = this.didSelectVerbAtIndex.bind(this);
    this.didSelectDeviceAtIndex = this.didSelectDeviceAtIndex.bind(this);
    this.didSelectPlaceAtIndex = this.didSelectPlaceAtIndex.bind(this);
    this.didSelectTimeAtIndex = this.didSelectTimeAtIndex.bind(this);
  }
  
  componentDidMount() {

  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    
    if(prevProps.number !== this.props.number) {
      this.setState({newRule: this.makeNewRule()});
    }
    
  }
  
  makeNewRule() {
    var newRule = cloneDeep(TechnologyPlanGameData.rulePrototype);
        
    newRule.person = '';
    newRule.verb = TechnologyPlanGameData.verbOptions[0].name;
    newRule.device = TechnologyPlanGameData.deviceOptions[0].name;
    newRule.place = TechnologyPlanGameData.placeOptions[0].name;
    newRule.time = TechnologyPlanGameData.timeOptions[0].name;
    return newRule;
  }

  didSelectPersonAtIndex(index) {
    var value = this.props.people[index], 
        newRuleMutable = this.state.newRule;
        
    newRuleMutable.person = value;
    newRuleMutable.colour = this.colourForPerson(value);
    
    this.setState({person: value, newRule: newRuleMutable});
  }
  
  didSelectVerbAtIndex(index) {
    var value = this.state.verbOptions[index], 
        newRuleMutable = this.state.newRule;
        
    newRuleMutable.verb = value.name;
    this.setState({verb: value, newRule: newRuleMutable});
  }
  
  didSelectDeviceAtIndex(index) {
    var value = this.state.deviceOptions[index], 
        newRuleMutable = this.state.newRule;
        
    newRuleMutable.device = value.name;
    this.setState({device: value, newRule: newRuleMutable});
  }
  
  didSelectPlaceAtIndex(index) {
    var value = this.state.placeOptions[index],
        newRuleMutable = this.state.newRule;
        
    newRuleMutable.place = value.name;
    this.setState({place: value, newRule: newRuleMutable});
  }
  
  didSelectTimeAtIndex(index) {
    var value = this.state.timeOptions[index],
        newRuleMutable = this.state.newRule;
        
    newRuleMutable.time = value.name;
    this.setState({time: value, newRule: newRuleMutable});
  }

  colourForPerson(query) {
    const { people } = this.props;
    var index = people.findIndex(person => query.toLowerCase() === person.toLowerCase());
    return (index >= 0) ? this.colourForIndex(index) : 0;
  }
  
  colourForIndex(index) {
    
    const colours = TechnologyPlanGameData.colours;
    
    while(index >= colours.length) {
      index -= colours.length;
    }
    
    return colours[index];
  }
  
  selectedAddRule() {
    this.props.onAdd(this.state.newRule);
  }
  
  selectedFinish() {
    this.props.onFinish(this.state.newRule);
  }
  
  render() {

    const { newRule, verbOptions, deviceOptions, placeOptions, timeOptions, peopleOptions} = this.state;    
    const { rules, number } = this.props;
    
    var self = this,
        addRuleButtonClasses = ['button', 'button--wide button--wide--add'], 
        finishButtonClasses = ['button button--wide', 'button--wide--tick'];
        
        if(!newRule.person.length) {
          addRuleButtonClasses.push('button--wide button--disabled');
        }
        
        if(!rules.length) {
          finishButtonClasses.push('button--disabled');
        }
        
    return (
      

      <div className="add-rule">
        <div className="container">          
          <div className="add-rule__header">
            <h1 className="title">{'Rule '+number}</h1>
            <p>Click on the coloured words to make changes</p>
          </div>
          
          <div className="add-rule__body">
            <RuleOption onSelectOptionAtIndex={self.didSelectPersonAtIndex} colour={self.colourForPerson(newRule.person)} options={peopleOptions} value={(newRule.person.length) ? newRule.person : 'Select Person'} /> 
            <RuleOption onSelectOptionAtIndex={self.didSelectVerbAtIndex} colour="purple" options={verbOptions} value={newRule.verb} />
            <span> use the </span>
            <RuleOption onSelectOptionAtIndex={self.didSelectDeviceAtIndex} colour="green" options={deviceOptions} value={newRule.device} />
            <span> in <br />the </span>
            <RuleOption colour="red" options={placeOptions} onSelectOptionAtIndex={self.didSelectPlaceAtIndex} value={newRule.place} />
            <span> at </span>
            <RuleOption colour="blue" options={timeOptions} onSelectOptionAtIndex={self.didSelectTimeAtIndex} value={newRule.time} />
            <span>.</span>
          </div>

          <div className="add-rule__footer">          
            <button onClick={(e) => {e.preventDefault(); self.selectedAddRule()}} type="submit" className={addRuleButtonClasses.join(' ')}><span>Add Rule</span></button>
            <button onClick={(e) => {e.preventDefault(); self.selectedFinish()}} type="submit" className={finishButtonClasses.join(' ')}><span>Finish</span></button>
          </div>
          
        </div>
      </div>
      
    )
  }
};

export default withRouter(AddRule);
import React from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { withRouter } from 'react-router-dom'
// import { ShareThatPhotoGameData } from './../../data/GameData';
import { photoTransition } from './../../data/Transitions';
import { ReactSVG } from 'react-svg';

class Touchpoint extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      isShowingPicker: false,
      isTransitioning: false,
      isShowingCameraApp: false,
      fromPerson: null,
      hasTakenPhoto: false,
      selectedPerson: null,
    };
    
    this.onSelectedTakePhoto = this.onSelectedTakePhoto.bind(this);
    
  }
  
  componentDidUpdate(prevProps, prevState) {
    var self = this;
    
    if(!prevProps.isActive && this.props.isActive) {
/*
      // Last person on the chain must be the recipient, so adding that to state
      self.setState({
        toPerson: (self.props.shareChain.length) ? self.props.shareChain[self.props.shareChain.length-1] : null,
      });
*/
      
      setTimeout(function() {
        self.setState({isShowingPicker: true});
      }, 3000);
    }
    
  }
  
  componentDidMount() {
    var self = this;
    
    if(this.props.device === 'selfie-phone' && this.props.isActive) {
      self.setState({isShowingCameraApp: true});
    }
    
    this.preloadImages();
  }
  
  preloadImages() {
    
    var imageElements = [];
    
    this.props.people.forEach(person => {

      const image = new Image().src = require('../../images/profile-'+person.id+'.png');
      const imageSelected = new Image().src = require('../../images/profile-'+person.id+'-selected.png');

      imageElements.push(image);
      imageElements.push(imageSelected);

    })

  }
  
  onSelectedTakePhoto() {
    var self = this;
    
    self.setState({hasTakenPhoto: true});
    
    setTimeout(function() {
      self.setState({isShowingCameraApp: false, isShowingPicker: true});
    }, 1500);
  }
  
  personIsInShareChain(person) {
    const match = this.props.shareChain.find((item) => {
        return item.id === person.id;
    });
    return (match != null);
  }
  
  willSelectPerson(person) {
    var self = this;
    
    if(this.state.selectedPerson != null) {
      return;
    }
    
    self.setState({selectedPerson: person});
    
    self.props.onSelectPerson(person);
    
    setTimeout(function() {
      self.setState({isTransitioning: true});
      //self.props.onSelectPerson(person);
    }, 1500);
    
    setTimeout(function() {
      self.setState({isTransitioning: false});
    }, 1500+5000);

  }
  
  getPickerTitle() {
    
    const { toPerson } = this.props;
    const { selectedPerson } = this.state;
    
    if(selectedPerson != null) {
      return (toPerson != null) ? toPerson.name+"'s "+selectedPerson.relationship+" "+selectedPerson.name : "Suzie's "+selectedPerson.relationship+" "+selectedPerson.name;
    }
    
    if(toPerson != null) {
      
  		switch (toPerson.device) {
  		
  			case 'laptop':
  				return toPerson.name+' receives Suzie’s photo on '+toPerson.pronouns[1]+' laptop and decides to share it with...';
  			
  			case 'tablet':
  				return toPerson.name+' recieves her photo on '+toPerson.pronouns[1]+' tablet and then shares it with...';
  				
  			case 'phone':
  				return toPerson.name+' recieves her photo on '+toPerson.pronouns[1]+' phone and then shares it with...';
  			  
  			default:
  			
  		}
      
    }
    
    return 'Good job, now who do you want her to share it with?'; //'Who do you want to share it with?';
  }
  
  render() {
    
    const { isShowingPicker, isTransitioning, selectedPerson, isShowingCameraApp, hasTakenPhoto } = this.state;
    const { toPerson, people, isActive, wasActive } = this.props;
    
    //var toPerson = (this.props.shareChain.length) ? this.props.shareChain[this.props.shareChain.length-1] : null;
    var device = (toPerson != null) ? toPerson.device : 'unknown';
    
    if(this.props.device) {
      device = this.props.device;
    }
    
    var self = this,
        wrapperClasses = ['photo-journey__content__section', 'photo-journey__content__section--'+device], 
        deviceClasses = ['device', 'device--'+device];
    
    if(isTransitioning) {
      wrapperClasses.push('photo-journey__content__section--transitioning');
    }
    
    if(isActive) {
      wrapperClasses.push('photo-journey__content__section--active');
    }
    
    if(wasActive) {
      wrapperClasses.push('photo-journey__content__section--was-active');
    }
    
    if(device === 'selfie-phone' && isShowingCameraApp) {
      deviceClasses.push('device--'+device+'--showing-camera-app');
    }
    
    if(device === 'selfie-phone' && hasTakenPhoto) {
      deviceClasses.push('device--'+device+'--has-taken-photo');
    }
    
    return (
      
      <div className={wrapperClasses.join(' ')}>
        
        <div className="photo-journey__content__section__device">
          
          {
              device === 'selfie-phone' ?
              
                <>
                
                  <AnimatePresence>
                    {(!hasTakenPhoto) && (
                      <motion.div 
                        className="device__message"
                        initial={{ opacity: 0, }}
                        animate={{opacity: 1, transition: { duration: 0.5, ease: 'easeIn' }}}
                        exit={{ opacity: 0, }}
                      >
                        <h1>Help Suzie take a photo of herself</h1>
                      </motion.div>
                    )}
                  </AnimatePresence>
                
                  <div className={ deviceClasses.join(' ') }>
                    <div className="device__display">
                      <div className="device__display__image"></div>
                      <div className="device__display__toolbar">
                        <button onClick={this.onSelectedTakePhoto} type="submit" className="device__display__toolbar__button"><span>Take Photo</span></button>
                        <p className="device__display__toolbar__label">Take Photo</p>
                      </div>
                    </div>
                    <div className="device__bezel"></div>
                  </div>
                  
                  <AnimatePresence>
                    {(!hasTakenPhoto) && (
                      <motion.div 
                        className="device__detail"
                        initial={{ opacity: 0, }}
                        animate={{opacity: 1, transition: { duration: 0.5, ease: 'easeIn' }}}
                        exit={{ opacity: 0, }}
                      >
                        <div className="device__detail__image"></div>
                    </motion.div>
                    )}
                  </AnimatePresence>
                  
                </>
          
                  :
          
                <div className={'device device--'+device}>
                  <div className="device__display">
                    <div className="device__preloader"><span></span></div>
                    <div className="device__display__image"></div>
                  </div>
                  <div className="device__bezel"></div>
                  
                  {(toPerson) && (
                    <div className={'person-profile person-profile--person'+toPerson.id}></div>
                  )}
                  
                  
                </div>
                
          }
          

        </div>
        
        <div className="photo-journey__content__section__options">
          
          <AnimatePresence>
            {(isShowingPicker && isActive) && (
              
              <motion.div 
                className="profile-picker-wrapper"
                initial={{ opacity: 0, }}
                animate={{opacity: 1, transition: { duration: 0.5, ease: 'easeIn' }}}
                exit={{ opacity: 0, }}
              >
                <h2>{ self.getPickerTitle() }</h2>
                
                <ul className="profile-picker">
                  
                  <AnimatePresence>
                    {people.map((person, index) => {
                      
                      var classes = ['person-profile', 'person-profile--person'+person.id];
                      
                      if(selectedPerson != null && (selectedPerson.id === person.id)) {
                        classes.push('person-profile--selected');
                      }
                      
                      if(self.personIsInShareChain(person)) {
                        classes.push('person-profile--disabled');
                      }
                      
                      return (
                        <motion.li 
                          key={person.id}
                          initial={{ opacity: 0, scale: 0, }}
                          animate={{opacity: 1, scale: 1, transition: { delay: (index*0.1), type: 'spring', stiffness: 200, damping: 10}}}
                          exit={{ opacity: 0, scale: 0, }}
                        >
                          <button 
                            onClick={() => {self.willSelectPerson(person)} } 
                            className={ classes.join(' ') }
                          >
                            <span className="person-profile__tooltip"><ReactSVG src={require('../../images/'+person.device+'-white.svg')} />{ person.name }</span>
                            <span className={'person-profile__device person-profile__device--'+person.device}></span>
                          </button>
                        </motion.li>
                      )
                    })}
                  </AnimatePresence>
      
                </ul>
              </motion.div>
              
            )}
          </AnimatePresence>
          
        </div>
        
        <AnimatePresence>
          {isTransitioning && (
            <motion.div 
              className="photo-journey__content__section__dotted-line"
              initial={{ opacity: 0, }}
              animate={{opacity: 1, transition: { duration: 0.5, ease: 'easeIn' }}}
              exit={{ opacity: 0, }}
            >
              <motion.div 
                className="photo-wrapper"
                variants={photoTransition}
                initial="start"
                animate={isTransitioning ? "end" : "start"}
              ><div className="photo"></div></motion.div>
            </motion.div>
            
          )}
        </AnimatePresence>
        
      </div>
      
    )
  }
};


export default withRouter(Touchpoint);
import React from 'react';
import { withRouter } from 'react-router-dom';
import logo from '../images/logo-playing-it-safe.svg';

class Header extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      topScrollThreshold: 50,
      isScrolled: false,
    };
    
    //this.didSelectScenario = this.didSelectScenario.bind(this);
  }
  
  componentDidMount() {
    this.setState({isMounted: true})
    window.addEventListener('scroll', this.onScroll);
  }
  
  onScroll = () => {
    this.setState({
      isScrolled: (window.scrollY > this.state.topScrollThreshold),
    });
  }
  
  render() {
    
    var self = this;

    return (
      
      <header id="header" className={ (this.state.isScrolled ? 'is-scrolled' : 'top')}>
        <div className="container">
          <div className="header__body">
            
            <div className="header__main">
              <a onClick={(e) => {e.preventDefault(); self.props.onSelectBrandLogo()}} className="header__brand" href="https://playingitsafe.com.au"><img src={logo} alt="Playing IT Safe" /></a>
            </div>
            
            <div className="header__left">
              <a onClick={(e) => {e.preventDefault(); self.props.onSelectBack()}} className="header__back" href="https://playingitsafe.com.au">Back</a>
            </div>
            
            <div className="header__right">
              
              {(this.props.onSelectMenu) && (
                <button onClick={this.props.onSelectMenu} className="header__menu"><span>Menu</span></button>
              )}
              
            </div>
            
          </div>
        </div>
      </header>
      
    )
  }
};


export default withRouter(Header);

import React from 'react';
import { motion } from "framer-motion";
import { withRouter } from 'react-router-dom'
import { ReactSVG } from 'react-svg';
import { TechnologyPlanGameData } from './../../data/GameData';

import { planBodyTransition, planItemTransition } from './../../data/Transitions';

import iconSunrise from '../../images/icon-sunset.svg';
import iconSun from '../../images/icon-sun.svg';
import iconMoon from '../../images/icon-moon.svg';
import iconSunset from '../../images/icon-sunset.svg';

class Summary extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      isMounted: false,
      allDayRules: ['all day', 'any time'],
      morningTimes: ['morning'],
      middayTimes: ['daytime', 'midday'],
      afternoonTimes: ['afternoon'],
      eveningTimes: ['evening', 'dinnertime', 'bedtime'],
      
      verbOptions: TechnologyPlanGameData.verbOptions,
      deviceOptions: TechnologyPlanGameData.deviceOptions,
      placeOptions: TechnologyPlanGameData.placeOptions,
      timeOptions: TechnologyPlanGameData.timeOptions,
    };
    
//     this._renderRule = this._renderRule.bind(this);
//     this._renderIcon = this._renderIcon.bind(this);
  }
  
  componentDidMount() {
    this.setState({isMounted: true})
  }

  allDayRules() {
    var self = this;
    return self.props.rules.filter((rule) => {
      return self.state.allDayRules.includes(rule.time);
    });
  }
  
  morningRules() {
    var self = this;
    return self.props.rules.filter((rule) => {
      return self.state.morningTimes.includes(rule.time);
    });
  }
  
  middayRules() {
    var self = this;
    return self.props.rules.filter((rule) => {
      return self.state.middayTimes.includes(rule.time);
    });
  }
  
  afternoonRules() {
    var self = this;
    return self.props.rules.filter((rule) => {
      return self.state.afternoonTimes.includes(rule.time);
    });
  }
  
  eveningRules() {
    var self = this;
    return self.props.rules.filter((rule) => {
      return self.state.eveningTimes.includes(rule.time);
    });
  }

  _renderIcon(type, value) {
    
    var icon = '';
    
		switch (type) {
		
			case 'person':
				icon = 'icon-profile.svg';
			break;
			
			case 'verb':
  			var verbOption = this.state.verbOptions.find(item => item.name === value);
				icon = (verbOption) ? verbOption.icon : '';
			break;
			
			case 'device':
  			var deviceOption = this.state.deviceOptions.find(item => item.name === value);
				icon = (deviceOption) ? deviceOption.icon : '';
			break;
			
			case 'place':
  			var placeOption = this.state.placeOptions.find(item => item.name === value);
				icon = (placeOption) ? placeOption.icon : '';
			break;
			
			case 'time':
  			var timeOption = this.state.timeOptions.find(item => item.name === value);
				icon = (timeOption) ? timeOption.icon : '';
			break;
			  
			default:
			
		}
		
		if(!icon.length) {
  		return ('');
		}
    
    return (
      <div className="icon">
        <ReactSVG src={require('../../images/'+icon)} />
      </div>
    )
  }
  
  _renderRule(rule, index) {
    return (
      <motion.li key={index} className={ rule.colour } variants={planItemTransition} initial="unmounted">
      <div className={['tech-plan__body__section__rule', rule.colour].join(' ')}>
          <strong>{this._renderIcon('person', rule.person)}{ rule.person } </strong> 
          <strong>{this._renderIcon('verb', rule.verb)}{ rule.verb }</strong><span> use the </span>
          <strong>{this._renderIcon('device', rule.device)}{ rule.device }</strong><span> in the </span>
          <strong>{this._renderIcon('place', rule.place)}{ rule.place }</strong><span> at </span>
          <strong>{this._renderIcon('time', rule.time)}{ rule.time }</strong><span>.</span>
        </div>
      </motion.li>
    )
  }
  
  
  render() {

    const { isMounted } = this.state;
    
    var self = this;
    
    return (
      
      <div className="container">
        <div className="tech-plan">
          <div className="tech-plan__header">
            <h1>Our Technology Plan</h1>
          </div>
          
          <motion.div 
            className="tech-plan__body"
            variants={planBodyTransition}
            initial="unmounted"
            animate={isMounted ? "mounted" : "unmounted"}
          >

            {(self.allDayRules().length > 0) && (

              <div className="tech-plan__body__section tech-plan__body__section--all-day">
                
                <div className="tech-plan__body__section__icon">
                  <ReactSVG src={iconSun} />
                  <h2>All Day</h2>
                </div>
                
                <ul>
                  
                  {self.allDayRules().map((rule, index) => {
                    return self._renderRule(rule, index)
                  })}
  
                </ul>
              
              </div>

            )}


            
            <div className="tech-plan__body__section tech-plan__body__section--morning">
              
              <div className="tech-plan__body__section__icon">
                <ReactSVG src={iconSunrise} />
                <h2>Morning</h2>
              </div>
              
              <ul>
                
                {(!self.morningRules().length) && (
                  <motion.li className="grey" variants={planItemTransition} initial="unmounted">
                    <div onClick={(e) => self.props.onAddNewRule()} className={['tech-plan__body__section__rule', 'grey'].join(' ')}>
                      Add rule.
                    </div>
                  </motion.li>
                )}
                
                {self.morningRules().map((rule, index) => {
                  return self._renderRule(rule, index)
                })}

              </ul>
            
            </div>
            
            <div className="tech-plan__body__section tech-plan__body__section--midday">
              
              <div className="tech-plan__body__section__icon">
                <ReactSVG src={iconSun} />
                <h2>Mid-day</h2>
              </div>
              
              <ul>
                
                {(!self.middayRules().length) && (
                  <motion.li className="grey" variants={planItemTransition} initial="unmounted">
                    <div onClick={(e) => self.props.onAddNewRule()} className={['tech-plan__body__section__rule', 'grey'].join(' ')}>
                      Add rule.
                    </div>
                  </motion.li>
                )}
                
                {self.middayRules().map((rule, index) => {
                  return self._renderRule(rule, index)
                })}

              </ul>
            
            </div>
            
            <div className="tech-plan__body__section tech-plan__body__section--afternoon">
              
              <div className="tech-plan__body__section__icon">
                <ReactSVG src={iconSunset} />
                <h2>Afternoon</h2>
              </div>
              
              <ul>
                
                {(!self.afternoonRules().length) && (
                  <motion.li className="grey" variants={planItemTransition} initial="unmounted">
                    <div onClick={(e) => self.props.onAddNewRule()} className={['tech-plan__body__section__rule', 'grey'].join(' ')}>
                      Add rule.
                    </div>
                  </motion.li>
                )}
                
                {self.afternoonRules().map((rule, index) => {
                  return self._renderRule(rule, index)
                })}
                
              </ul>
            
            </div>
            
            <div className="tech-plan__body__section tech-plan__body__section--evening">
              
              <div className="tech-plan__body__section__icon">
                <ReactSVG src={iconMoon} />
                <h2>Evening</h2>
              </div>
              
              <ul>
                
                {(!self.eveningRules().length) && (
                  <motion.li className="grey" variants={planItemTransition} initial="unmounted">
                    <div onClick={(e) => self.props.onAddNewRule()} className={['tech-plan__body__section__rule', 'grey'].join(' ')}>
                      Add rule.
                    </div>
                  </motion.li>
                )}
                
                {self.eveningRules().map((rule, index) => {
                  return self._renderRule(rule, index)
                })}
                
              </ul>
            
            </div>
            
          </motion.div>
          
          <div className="tech-plan__footer">
            <button onClick={(e) => self.props.onAddNewRule()} type="submit" className="button button--wide button--wide--add"><span>Add New Rule</span></button>
            <button onClick={(e) => self.props.onPrint()} type="submit" className="button button--wide button--wide--print"><span>Print</span></button>
          </div>
          
        </div>
      </div>
      
    )
  }
};


export default withRouter(Summary);